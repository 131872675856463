import "rc-notification/assets/index.css";
import React, { lazy, Suspense } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { NotificationContext } from "./context";

const Landing = lazy(() => import("./containers/Landing/index"));
const NotFound = lazy(() => import("./containers/NotFound/index"));
const MainScreen = lazy(() => import("./containers/MainScreen/index"));

function App() {
  return (
    // <NotificationContext.Provider>
      <Suspense fallback={null}>
        <Router basename={window.location.pathname} className="App">
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/upload/:session" exact component={MainScreen} />
            <Route path="(.*)" component={NotFound} />
          </Switch>
        </Router>
      </Suspense>
    // </NotificationContext.Provider>
  );
}

export default App;
